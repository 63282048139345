import successImage from '../src/media/success.png';
function SuccessPage(){
    return (
      
    <div class="container-fluid">
    <div class="row justify-content-center">
        <div class=" col-lg-6 col-md-8">
            <div class="card p-3">
                <section id="main-content" class="container">
                    <div class="row-wrapper-x">
                       <section class="wpb_row  ">
                          <div class="wpb_column vc_column_container vc_col-sm-12">
                             <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                   <div  class="wpb_single_image wpb_content_element text-center">
                                    <img src={successImage} style={{"width":"200px"}} alt="" />
                                     <h2 class="text-success">Paiement effectué</h2>
                                     <p>Paiement effectuer avec succès nous vous remercions</p>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </section>
                    </div>
                 </section>
            </div>
        </div>
    </div>
</div>
    )
}

export default SuccessPage;