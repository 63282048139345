import {Routes as Switch, Route} from 'react-router-dom';
import Guichet_Page from './guichet_page';
import ErrorPage from './failed_page';
import SuccessPage from './success_page';

function Main(){
    return (
        <div>
            <Switch>
                 {/* <Route path="/" element={<Guichet_Page />} /> */}
                 <Route path="/:token" element={<Guichet_Page />} />
                 <Route path="/transaction/failed" element={<ErrorPage />} />
                 <Route path="/transaction/success" element={<SuccessPage />} />
            </Switch>        
        </div>
    )
}

export default Main;